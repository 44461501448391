const formFields = () => {
    const inputText = document.querySelectorAll(".form-fields label input, .form-fields label select, .form-fields label textarea");

    for (let item of inputText) { // relitere
        item.addEventListener("focusout", (e) => {
            let nextSibling = item.nextElementSibling;
            if (item.value.length == 0) {
                nextSibling.style.opacity = null;
            } else {
                nextSibling.style.opacity = '0';
            }
        });
    }
};

formFields();
