let reviewsSlider;
const reviewsSliderWrap = document.querySelectorAll('.friends-slider .swiper');

for (let i = 0; i < reviewsSliderWrap.length; i++) {
    reviewsSlider = new Swiper(reviewsSliderWrap[i], {
        loop: true,
        autoplay: {
            delay: 0,
            disableOnInteraction: false
        },
        speed: 5000,
        slidesPerView: 'auto',
        spaceBetween: 0,
        grabCursor: true,
        mousewheelControl: true,
        keyboardControl: true,
        watchSlidesVisibility: true,
        navigation: {
            disabledClass: "disabled",
            nextEl: ".btn-next",
            prevEl: ".btn-prev",
        },
    });
}

